.separators-form {
    height: 100%;

    .form-container {
        padding-bottom: 25px;
    }
}

.separators-list {
    margin-bottom: 20px;
    max-height: calc(100% - 120px);
    overflow-y: auto;
}

.separators-list-add {
    margin-bottom: 20px;
}

.separator-list-item {
    position: relative;
}

.separator-list-item-delete {
    @include size(20);
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;

    svg {
        fill: $actionColor;
    }
}
