.header {
    height: $headerHeight;
    padding: 25px 15px;
    display: flex;
    background: linear-gradient(90deg, #b74ca4 0%, #6e3a8b 100%);
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-controls {
    @include row;
}

.header-item {
    margin-left: 15px;
    align-items: center;
    @include row;
}

.header-menu {
    width: 30px;
    height: 50px;
    padding: 10px 0px;
    cursor: pointer;

    svg {
        color: #fff;
    }
}

.about {
    color: #5b5a69;
    font-size: 11px;
    cursor: default;
}
.version{
    display: flex;
}
