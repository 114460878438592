.extracted-preview-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    position: relative;
    padding: 20px;
    text-align: center;
}

.extracted-preview {
    @include container-shadow;
    padding: 30px;
    min-width: 660px;
    min-height: 600px;
    display: inline-block;
    text-align: initial;

    .divider {
        margin: 10px 0 15px;
    }
}

.extracted-preview-title {
    font-size: 16px;
}

.extracted-preview-general-fields {
    margin: 20px 0;
}

.extracted-preview-gf-label {
    font-size: 12px;
    padding: 6px 0;
}

.extracted-preview-gf-value {
    font-size: 12px;
    color: #5872e9;
    padding: 6px 0 6px 20px;
}

.extracted-preview-table {
    margin-top: 10px;
    column-span: none;

    td,
    th {
        border-left: 1px solid #979797;
        border-top: 1px solid #979797;
        padding: 8px 8px 0px 0px;

        &:last-child {
            border-right: 1px solid #979797;
            position: relative;
        }
    }

    th {
        background-color: #793c8e;
        color: #fff;
    }

    tr:last-child {
        td {
            border-bottom: 1px solid #979797;
        }
    }
}

.extracted-preview-edit {
    @include row-centered;
    justify-content: space-between;
    min-height: 34px;
    margin-left: 3px;
    .ant-input {
        padding: 2px 8px;
        min-width: 120px;
    }

    &:hover {
        .extracted-edit-button {
            visibility: visible;
        }
    }
}

.extracted-edit-controls {
    margin-left: 10px;
    white-space: nowrap;
}

.extracted-edit-button {
    visibility: hidden;
}

.extracted-edit-options {
    min-width: 300px;
    height: 30px;
}

.extracted-error {
    border-radius: 8px;
    border: 1px solid $errorColor;
    color: $errorColor;
    padding: 5px 10px;
    margin-left: -10px;
}

td .extracted-error {
    margin-left: 2px;
    margin-bottom: 5px;
}

.extracted-preview-last {
    display: flex;
    flex-direction: row;
}

.extracted-remove-line-icon {
    @include size(32);
    position: absolute;
    top: 3px;
    left: 0;
    padding: 8px;
    cursor: pointer;
    display: inline-block;
}

.extracted-table-values {
    padding-top: 10px;

    .ant-table-thead > tr > th.ant-table-cell,
    .ant-table-thead > tr > td.ant-table-cell {
        padding: 8px;
        border-radius: 0 !important;
        background-color: #793c8e;
        color: #fff;
        border: 1px solid #979797;

        &::before {
            display: none;
        }
    }

    .ant-table-content {
        border: 1px solid #e2e2e2;
    }

    .ant-table-tbody > tr > td.ant-table-cell {
        padding: 5px;
    }
}
