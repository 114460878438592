.container {
    @include container-shadow;
    padding: 10px;

    &.list {
        padding: 24px 33px;
    }
}

.container-center {
    @include fullsize;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.divider {
    border-bottom: 1px solid #e2d5d5;
    margin: 5px 0;

    &.list {
        margin: 0;
    }
}

.divider-vertical {
    border-right: 1px solid #dcdcdc;
    height: 42px;
}

.plus-button {
    @include row;
    font-size: 14px;
    line-height: 1;
    align-items: center;

    svg {
        fill: #fff;
    }
}

.plus-icon {
    padding: 3px 0 0 4px;
    width: 24px;
    height: 24px;
}

.filter {
    width: 180px;
    height: 42px;
    position: relative;

    &.ant-select .ant-select-selector {
        border: none;
        background-color: #ededed;
    }

    .ant-select-item-option-content {
        text-align: right;
    }

    .ant-select-selection-item {
        text-align: right;
        padding-right: 30px;
        color: $primaryColor;
        font-size: 13px;
    }

    &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('../../assets/icons/filter.svg') no-repeat 0 0;
        background-size: contain;
        position: absolute;
        left: 17px;
        top: 13px;
    }
}

.tooltip-container {
    @include row-centered;
}

.tooltip-icon {
    width: 16px;
    height: 16px;

    svg {
        color: $primaryColor;
    }
}

.tooltip-suffix {
    margin-left: 10px;
}

.block {
    padding: 24px 33px;
    margin-bottom: 25px;

    .divider {
        margin-bottom: 20px;
    }
}

.block-header {
    @include row-centered;

    h2 {
        margin-right: 8px;
    }
}

.block-content {
    position: relative;
}

.block-controls {
    align-self: flex-end;
}

.block-list {
    .block-header {
        height: 45px;
        margin-bottom: 12px;
    }
}

.collapsible-block {
    @include container-shadow;
    margin-bottom: 25px;
    border: none;

    .ant-collapse-item {
        border: none;
    }

    &.ant-collapse .ant-collapse-header {
        padding: 20px 25px 19px;
        line-height: 1;
    }

    .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
            padding: 0 25px 15px;
        }
    }
}

.with-tooltip {
    @include row-centered;

    .tooltip-container {
        margin-left: 5px;
    }
}

.single-list {
    margin: 10px 0;
}

.single-list-item {
    @include row-centered;
    margin: 8px 0;
    padding: 10px 10px 10px 20px;
    border-radius: 8px;
    border: none;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.single-list-item-title {
    font-size: 12px;
}

.single-list-item-selected {
    &::before {
        content: '';
        display: block;
        border-radius: 8px 0px 0px 8px;
        background: $secondaryColor;
        box-shadow: 0px 0px 20px 0px rgba(45, 46, 116, 0.05);
        height: 100%;
        width: 8px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .single-list-item-title {
        font-weight: 700;
        color: $primaryColor;
    }
}

.badge {
    position: relative;
    height: 20px;
    line-height: 21px;
    border-radius: 20px;
    padding: 0 15px 0 28px;
    display: inline-block;
    background-color: #23c17c;
    font-size: 11px;
    color: #fff;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 5px;
        left: 11px;
        top: 5px;
        transform: rotate(-45deg);
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
    }
}

.status-badge {
    border-radius: 10px;
    min-width: 110px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
}

.status-badge-active {
    background-color: #e3e5e6;
    color: $listFontColor;
}

.status-badge-not_active {
    background-color: #ff7878;
    color: #fff;
}

.status-badge-approved {
    background-color: #23c17c;
    color: #fff;
}

.status-badge-terminated {
    background-color: #444444;
    color: #fff;
}

.item-menu-icon {
    @include size(18);
    cursor: pointer;

    svg {
        color: #9998b7;
    }
}

.selection-button {
    color: $minorTextColor;
    padding: 10px 20px;
    margin: -10px 0;
    font-size: 11px;
    position: relative;

    &.active {
        color: $secondaryColor;
        font-weight: 700;

        &::after {
            content: '';
            position: absolute;
            height: 2px;
            background-color: $secondaryColor;
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }
}

.toggle-button {
    @include row-centered;
    cursor: pointer;

    .ant-switch {
        margin-right: 10px;
    }

    .ant-switch-inner-checked svg {
        margin-top: 5px;
    }
}

.loading {
    min-height: 200px;
}

.email-link {
    cursor: pointer;
    font-size: 14px;
}

.search-icon {
    width: 16px;
    height: 16px;
    margin-right: 3px;

    svg {
        fill: $primaryColor;
    }
}
