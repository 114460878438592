.list {
    flex: 1;
}

.list .ant-table-wrapper .ant-table-cell {
    color: $listFontColor;
    padding: 10px 15px;
}

.list-actions {
    @include row;
    justify-content: flex-end;
    visibility: hidden;
}

.list-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    svg {
        fill: $actionColor;
    }

    &:hover {
        .list-action-text {
            color: $secondaryColor;
        }

        svg {
            fill: $secondaryColor;
        }
    }
}

.list-action-icon {
    width: 24px;
    height: 24px;
}

.list-action-text {
    margin-top: 3px;
    color: $actionColor;
    text-align: center;
}

.ant-table-cell-row-hover {
    .list-actions {
        visibility: visible;
    }
}

.list-icon-cel-text {
    @include row-centered;

    .ant-avatar {
        margin-right: 12px;
    }
}

.list-cel-text {
    color: $listFontColor;
    font-weight: 500;
    display: block;
}

.list-cel-subtext {
    color: $minorTextColor;
    font-weight: 400;
    font-size: 11px;
    &::first-letter {
        text-transform: uppercase;
    }
}

.list-header {
    @include row-centered;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 12px;
}
