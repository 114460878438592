.file-view {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    position: relative;
}

.file-view-pdf-area {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    position: relative;
    padding: 20px;
}

.file-view-pdf-container {
    @include container-shadow;
    padding: 30px;
    margin: auto;
    cursor: pointer;
}

.file-view-pdf-border {
    border: 1px solid #ccc;
    position: relative;
}

.file-view-shadow {
    box-shadow: inset 0 0 12px 0 $layoutBackground;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.file-pagination {
    @include container-shadow;
    position: absolute;
    bottom: 0;
    left: 30px;

    .ant-pagination-item {
        padding: 5px 10px;
        height: 40px;
    }

    .ant-pagination-item-active {
        border: none;
        background-color: $secondaryColor;

        a {
            color: #fff !important;
            font-size: 16px;
        }
    }
}
