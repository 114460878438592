.ant-input {
    line-height: 1.8;
}

.ant-input-number {
    width: 100%;
}

.ant-btn {
    box-shadow: none;
    padding: 8px 34px;
    height: 42px;
    font-size: 16px;
}

.ant-btn-primary {
    background-color: $secondaryColor;

    &:not(:disabled):not(.ant-btn-disabled) {
        &:hover {
            @include primaryHover;
        }

        &:active {
            @include primaryActive;
        }
    }
}

.ant-avatar {
    background-color: #e9e7fe;
    padding: 3px 9px 0;
}

.ant-select-single {
    height: 35px;
}

.ant-radio-group {
    width: 100%;
    display: flex;
}

.ant-radio-button-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.ant-radio-button {
    text-align: center;
}

.ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
        color: #fff;
        background: $secondaryColor;
        border-color: $radioBorderColor;

        &:first-child {
            border-color: $radioBorderColor;
        }

        &:hover {
            color: #fff;
            @include primaryHover;
            border-color: $radioBorderColor;

            &::before {
                background-color: $radioBorderColor;
            }
        }

        &:active {
            color: #fff;
            @include primaryActive;
        }

        &::before {
            background-color: $radioBorderColor;
        }
    }
}

.ant-select-item-option {
    align-items: center;
}

.ant-select-item-option-content {
    font-size: 12px;
    color: $primaryColor;
}

.ant-modal {
    .ant-modal-content {
        padding: 30px 36px;
    }

    .ant-modal-title {
        font-size: 20px;
        font-style: normal;
        color: $defaultFontColor;
    }
}

.ant-btn.ant-btn-sm {
    height: 28px;
    font-size: 13px;
    padding: 6px 14px;
    line-height: 1;

    .ant-btn-icon .plus-icon {
        @include size(16);
    }
}

.ant-progress .ant-progress-bg {
    background-color: $secondaryColor;
}

.ant-tabs {
    .ant-tabs-nav {
        margin-bottom: 0;

        &::before {
            display: none;
        }
    }

    .ant-tabs-nav .ant-tabs-ink-bar {
        background-color: $sideMenuSelectedColor;
        height: 4px;
    }

    .ant-tabs-tab {
        padding: 5px 10px 18px;

        .ant-tabs-tab-btn {
            color: $primaryColor;
            font-size: 20px;
            font-weight: 400;
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                font-weight: 500;
                color: $primaryColor;
            }
        }
    }
}

.ant-tooltip {
    --antd-arrow-background-color: #fff;

    .ant-tooltip-inner {
        @include container-shadow;
        filter: drop-shadow(0px 0px 30px rgba(45, 46, 116, 0.07));
        color: $primaryColor;
        font-size: 12px;
    }
}

.ant-switch.ant-switch-checked {
    background: #2dd474;

    &:hover:not(.ant-switch-disabled) {
        background: #2dd474;
    }
}

.ant-checkbox {
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid #cacad6;

        &::after {
            border-right: 1px solid #333;
            border-bottom: 1px solid #333;
            width: 6px;
            height: 10px;
            margin-left: 0.5px;
        }
    }

    &.ant-checkbox-checked .ant-checkbox-inner {
        border: 1px solid #cacad6;
        background-color: #fff;
    }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border: 1px solid #cacad6;
    background-color: #fff;
}

.ant-select-disabled.ant-select.ant-select-single .ant-select-selector {
    background-color: #ededed;
}
