.login-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: $layoutBackground;
}

.login-content {
    position: relative;
    height: 60%;
    background: url('../../assets/login-bg.jpg') no-repeat 0 0;
    background-size: cover;
    display: flex;
    justify-content: flex-end;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.7689;
        background: linear-gradient(90deg, #b74ca4 0%, #6e3a8b 100%);
        z-index: 0;
    }
}

.login-logo {
    position: absolute;
    top: -80px;
    left: 80px;
}

.login-header {
    position: relative;
    width: 100%;
}

.login-component-wrapper {
    align-self: center;
    margin-right: 20%;
    z-index: 1;
}
