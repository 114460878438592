.form-list {
    margin: 10px 0;

    .form-container {
        margin-bottom: 35px;
    }
}

.form-list-items {
    margin: 25px 0;
}

.form-list-item {
    @include row-centered;
    justify-content: space-between;
    padding: 10px 25px;
    cursor: pointer;

    .ant-btn {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        @include container-shadow;

        .ant-btn {
            opacity: 1;
        }
    }
}

.form-list-item-title {
    font-size: 14px;
}

.form-list-item-buttons {
    @include row-centered;
    justify-content: space-between;
    margin-top: 20px;
    
    .ant-btn {
        padding: 6px 18px;
        line-height: 1.2;
        font-size: 14px;
    }
}
