.user-list-role {
    @include row-centered;
}

.user-list-role-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.users-plan {
    height: 40px;
}

.users-plan-count {
    color: $primaryColor;
}

.users-plan-upgrade {
    padding: 0 0 0 40px;
    font-size: 14px;
    height: auto;
}
