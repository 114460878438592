.zoom {
    @include container-shadow;
    border-radius: 40px;
    width: 50px;
    height: 200px;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2;
}

.zoom-button {
    @include size(32);
    padding: 6px;
    background-color: #f8f8f8;
    cursor: pointer;
    margin: auto;
    border-radius: 20px;
    position: absolute;
    left: 9px;

    &.plus {
        top: 11px;
    }

    &.minus {
        bottom: 11px;
    }

    svg {
        fill: $defaultFontColor;
    }

    &:hover {
        background-color: #78787832;
    }

    &:active {
        background-color: #36373825;
    }
}

.zoom-track {
    position: absolute;
    width: 9px;
    height: 96px;
    top: 52px;
    left: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: #78787832;
    }

    &:active {
        background-color: #36373825;
    }
}

.zoom-level {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $secondaryColor;
    width: 100%;
    height: 0;
    border-radius: 10px;
    transition: height 0.3s ease;
    pointer-events: none;
}
