.kpi-container {
    @include row;
}

.kpi-item {
    @include row;
    @include container-shadow;
    padding: 20px 16px 15px 26px;
    position: relative;
    flex: 1;
    justify-content: space-between;

    &:not(:last-child) {
        margin-right: 12px;
    }

    &::before {
        @include prefix(100%, #fff);
    }

    &.kpi-item-imports::before {
        background-color: #bc4ca6;
    }

    &.kpi-item-failed::before {
        background-color: #ff7474;
    }

    &.kpi-item-templates::before {
        background-color: #84d08c;
    }
}

.kpi-item-title {
    color: $primaryColor;
    font-size: 17px;
    white-space: nowrap;
}

.kpi-item-value {
    color: $listFontColor;
    font-size: 28px;
    font-weight: 500;
}

.kpi-item-action {
    margin-left: 10px;
    line-height: 25px;
}
