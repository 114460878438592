.system-settings-form {
    .ant-form-item-control-input {
        width: 33%;
    }

    .ant-form-item:last-child {
        margin-bottom: 0;
    }
}

.connected-integration {
    @include row-centered;
    padding: 20px;
    justify-content: space-between;
    max-width: 80%;

    .ant-avatar {
        margin-right: 22px;
        padding: 10px;
    }
}

.connected-integration-connection {
    @include row-centered;
    justify-content: flex-start;
}

.connected-integration-text {
    font-size: 18px;
}

.connected-integration-details {
    font-size: 12px;
    color: $minorTextColor;
}

.connected-integration-error {
    color: $errorColor;
}

.test-connection {
    @include row-centered;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 42px;
    max-height: 42px;

    .ant-btn {
        padding-left: 22px;
        padding-right: 22px;
    }
}

.test-connection-status {
    @include row-centered;
    margin-left: 10px;
    font-size: 12px;
}

.test-connection-status-icon {
    width: 28px;
    height: 28px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 5px;

    &.ok {
        background-image: url('../../assets/connection/ok.png');
    }

    &.fail {
        background-image: url('../../assets/connection/fail.png');
    }

    &.loading {
        background-image: url('../../assets/connection/loading.png');
    }
}

.translations-upload {
    margin-bottom: 25px;
    display: block;

    .ant-upload-drag {
        border: 2px dashed #cfcfcf;
        background-color: #fff;
        width: 340px;
        height: 100px;
    }
}

.translations-upload-icon {
    @include size(26);
    margin: auto;
}

.translations-upload-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
    font-weight: 400;
    max-width: 340px;
    padding: 0 20px;
    margin-top: 10px;
}

.translations-upload-text-link {
    color: $linkColor;
}

.translations-upload-content {
    &.translations-upload-error {
        color: $errorColor;
    }
}

.translations-download {
    @include row-centered;
    color: #5977ff;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 20px;

    .icon {
        @include size(24);
    }
}
.translations-sync {
    @include row-centered;
    color: #5977ff;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 80px;

    .icon {
        @include size(24);
    }
}
