.overview-tabs-container {
    padding: 16px 25px;
    flex: 1;

    .list {
        padding: 0;
        box-shadow: none;
    }
}

.overview-block {
    padding: 15px 22px;
}

.overview-stats {
    @include row-centered;
    margin: 10px 0;
    justify-content: space-between;
}

.overview-stats-money-saved {
    margin: 20px auto 10px;
    background-color: #f2f0f2;
    text-align: center;
    font-size: 18px;
    padding: 12px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
}

.share-stats-link {
    display: block;
    margin: 15px auto 0;
    text-align: center;
}
