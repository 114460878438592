.login-container {
    min-height: 75vh;
    width: 450px;
    padding: 50px 70px;
    box-shadow: 0px 0px 20px 0px rgba(45, 46, 116, 0.05);
    filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.26));

    .ant-btn {
        width: 100%;
        padding: 8px 34px;
        height: 42px;
        margin-top: 30px;
    }

    .ant-input-suffix {
        margin-top: -66px;
        margin-right: -8px;
    }

    h1 {
        margin: 20px 0;
    }
}

.login-container-logo {
    margin: auto;
}

.login-label .ant-form-item-label label {
    color: $defaultFontColor;
    font-size: 14px;
    font-weight: 500;
}

.login-password {
    margin-bottom: 10px;
}

.login-row {
    @include row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 12px;
}

.login-rememberme {
    margin: 0;
    label {
        color: $minorTextColor;
        font-size: 12px;
    }
}

.login-divider {
    margin: 20px 0;
}

.login-google-button {
    display: flex;
    justify-content: center;
    border: none;
    filter: drop-shadow(0px 0px 10px rgba(88, 99, 224, 0.27));
    margin-bottom: 20px;
}

.login-google-button-icon {
    width: 23px;
    height: 23px;
    background: url('../../assets/google-logo.png') 0px 0px / 100% 100% no-repeat;
}

.login-sign-up {
    text-align: center;
    font-size: 12px;
    color: $minorTextColor;

    a {
        font-weight: 700;
        color: $minorTextColor;
    }
}

.login-error {
    @include row-centered;
    justify-content: center;

    height: 70px;
    background-color: $errorBackground;
    margin: 10vh -70px -70px;
    border-radius: 0 0 8px 8px;

    .login-error-icon {
        @include size(28);
        padding: 4px;
        margin-right: 4px;

        svg {
            fill: #ff7878;
        }
    }

    .login-error-text {
        color: #2c3d53;
        font-size: 14px;
        font-weight: 500;
    }
}
