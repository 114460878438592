.user-info {
    @include row;
}

.user-info-text {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
}

.user-info-name {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 0.8;
    color: #fff;
    white-space: nowrap;
}

.user-info-role {
    color: #aeaeb5;
}

.user-info-image-upload {
    &.pressable {
        cursor: pointer;
    }
}

.user-info-image-container {
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
        max-height: 45px;
        width: auto;
        border: none;
        margin: 0;
    }
}

.user-info-image {
    max-width: 120px;
    max-height: 45px;
}

