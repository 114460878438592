.custom-date-range {
    padding: 10px;

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-form-item-label {
        padding-bottom: 5px;
    }
}

.filter.date-filter {
    &::after {
        background-image: url('../../assets/icons/calendar.svg');
    }

    &.custom {
        width: 210px;
    }
}
