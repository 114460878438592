.error-queue-status {
    .ant-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        display: none;
    }

    .ant-steps {
        width: 80%;
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 0;
            border-top: 1px solid #d4d4d4;
            width: 100%;
        }

        .ant-steps-item-icon {
            @include size(12);
            border: none;
            margin-inline-end: 0;

            .ant-steps-icon {
                display: none;
            }
        }

        .ant-steps-item-finish .ant-steps-item-icon {
            background-color: #23c17c;
        }

        .ant-steps-item-process .ant-steps-item-icon {
            background-color: #ff7878;
        }

        .ant-steps-item-wait .ant-steps-item-icon {
            background-color: #fff;
            border: 1px solid #d4d4d4;
        }
    }
}

.error-queue-status-message {
    color: $minorTextColor;
    font-size: 12px;
    margin-top: 5px;
}

.delete-error-queue-modal {
    min-width: 700px;
}
