.templates-layout {
    @include row;
    height: calc(100% - 66px);

    .templates-layout-left {
        flex: 1;
        margin-right: 25px;
        display: flex;
        max-width: 350px;
    }

    .templates-layout-right {
        flex: 1;
    }
}
