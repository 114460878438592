.side-menu {
    background-color: #fff;
    padding: 25px 20px;
}

.side-menu-item {
    @include row;
    height: 44px;
    border-radius: 8px;
    align-items: center;
    position: relative;
}

.side-menu-icon {
    width: 16px;
    height: 16px;
    margin-left: 22px;
    margin-right: 18px;
    color: $primaryColor;
}

.side-menu-text {
    color: $primaryColor;
    font-size: 13px;
}

.side-menu-count {
    text-align: right;
    flex-grow: 1;
    padding-right: 18px;
    color: $primaryColor;
}

.side-menu-item-selected {
    background-color: $layoutBackground;

    .side-menu-icon {
        color: $sideMenuSelectedColor;
    }

    .side-menu-text,
    .side-menu-count {
        font-weight: 700;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 6px;
        background-color: $sideMenuSelectedColor;
        border-radius: 8px 0 0 8px;
    }
}
