.documents-list-controls {
    @include row-centered;
}

.documents-list-controls-amount {
    margin-right: 15px;
    font-size: 16px;
    font-weight: 500;
}

.documents-list-controls-actions {
    &.ant-select .ant-select-selector {
        border: none;
        background-color: $secondaryColor;
        height: 37px;
        min-width: 160px;
        padding: 0 20px;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        color: #fff;
        font-size: 13px;
    }

    .ant-select-arrow svg {
        color: #fff;
    }
}

.documents-list-delete {
    max-height: 180px;
    overflow-y: auto;
}

.document-delete-reason {
    background-color: $defaultGray;
    padding: 10px 18px;
    margin: 10px 0;

    textarea {
        display: block;
        width: 100%;
        height: 110px;
        margin-top: 10px;
    }
}

.document-delete-button {
    background-color: #ff5c02;
}

.document-upload {
    display: block;

    .ant-upload-drag {
        border: 1px dashed #a5a5b2;
        background-color: #f8f8f8;
        width: 100%;
        height: 150px;
    }
}

.document-upload-icon {
    @include size(26);
    margin: auto;
}

.document-upload-text {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    max-width: 100%;
    max-height: 70px;
    padding: 0 20px;
    margin-top: 10px;
    color: $primaryColor;
}

.document-upload-delete-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}
