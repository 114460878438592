.search {
    align-self: center;
    width: 280px;
    margin-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.search-results-dropdown-content {
    width: 390px;
    min-height: 20px;
    max-height: 400px;
    padding: 5px 15px;
    overflow-y: auto;
}

.search-results-section {
    margin-top: 5px;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

.search-results-section-title {
    font-size: 15px;
    font-weight: 500;
}

.search-result-item {
    @include row-centered;
    justify-content: space-between;
    padding-bottom: 5px;
    cursor: pointer;
}

.search-result-item-text {
    color: $actionColor;
    max-width: 280px;

    &:hover {
        text-decoration: underline;
    }
}

.search-result-item-date {
    color: $minorTextColor;
    font-size: 12px;
}
