.logo {
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo-image {
        margin-right: 17px;
    }
}

.logo-image {
    background: url('../../assets/logos/color-icon-logo.svg') no-repeat 0 0;
    background-size: contain;
}

.logo-image-size-small {
    height: 40px;
    width: 40px;
}

.logo-image-size-normal {
    height: 56px;
    width: 56px;
}

.logo-image-size-big {
    height: 90px;
    width: 90px;
}

.logo-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $primaryColor;
}

.logo-type-header {
    .logo-image {
        background: url('../../assets/logos/bw-icon-logo.svg') no-repeat 0 0;
        margin-right: 11px;
    }

    .logo-text {
        font-size: 22px;
        color: #fff;
    }
}
