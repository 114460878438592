.error-page {
    height: 100%;

    .search {
        display: none;
    }
}

.error-page-container {
    height: 100%;
    padding: 250px 200px;
    position: relative;
}

.error-page-background {
    position: absolute;
    width: 100%;
    height: 400px;
    left: 0;
    top: 82px;
    background: url('../../assets/error-page-bg.png') no-repeat 0 0;
    background-size: cover;
    z-index: 0;
}

.error-page-content {
    padding: 50px;
    text-align: center;
}

.error-page-line {
    width: 80px;
    margin: 10px auto;
    height: 4px;
    background-color: $secondaryColor;
}

.error-page-code {
    font-size: 84px;
    font-weight: bold;
}

.error-page-text {
    font-size: 20px;
    padding: 0 100px;
}

.error-page-divider {
    border-bottom: 1px solid $dividerColor;
    margin: 30px 50px;
}

.error-page-links {
    font-size: 16px;
}
