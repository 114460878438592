.post-process-rules {
    .divider {
        margin-bottom: 25px;
    }

    .form-container {
        padding-bottom: 25px;
    }
}

.post-process-rule-field {
    @include row-centered;
    justify-content: space-between;
    margin: 10px;
    .ant-select-selector{
        border:none!important;
    }
}

.post-process-rule-field-name {
    font-weight: 700;
    min-width: 200px;
}

.post-process-rule-checkbox {
    margin-bottom: 10px;
}
