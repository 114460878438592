.default-layout {
    @include fullsize;
    display: flex;
    flex-direction: column;
}

.default-layout-section {
    @include row;
    flex: 1;
    height: calc(100% - $headerHeight);
}

.default-layout-side-menu {
    min-width: $sideMenuWidth;
    max-width: $sideMenuWidth;
    width: $sideMenuWidth;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.default-layout-content {
    background-color: $layoutBackground;
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: $pageVerticalPadding $pageHorizontalPadding;
}
