$defaultBackground: #fff;
$defaultFontColor: #2c3d53;
$defaultFontSize: 14px;
$headerHeight: 83px;
$pageHorizontalPadding: 62px;
$pageVerticalPadding: 37px;
$layoutBackground: #f8f8f8;
$primaryColor: #333277;
$secondaryColor: #8c4195;
$linkColor: #4897ff;
$minorTextColor: #898998;
$dividerColor: #e2d5d5;
$listFontColor: #5b5a69;
$actionColor: #3e92ff;
$radioBorderColor: #652c6b;
$defaultGray: #f9f9f9;
$errorColor: #ff3e3e;
$sideMenuSelectedColor: #bc4ca6;
$sideMenuWidth: 270px;
$errorBackground: #f8d6d6;
