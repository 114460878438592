.ant-drawer {
    .ant-drawer-header {
        border: none;
        padding: 25px 25px 0 25px;
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;
    }

    .ant-drawer-title {
        color: $primaryColor;
        font-size: 32px;
    }

    .ant-drawer-body {
        padding: 15px 25px 25px;
    }

    .ant-drawer-close {
        margin: 0;
    }
}

.form-container {
    margin: 0 0 20px 0;
    padding: 25px 25px 1px 25px;
}
