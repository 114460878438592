$documentHeight: 650px;

.document-preview-header {
    padding: 0;
    margin-bottom: 20px;

    .single-list-item {
        cursor: default;
        padding: 16px 35px;
    }

    .list-cel-text {
        font-size: 16px;
    }
}

.document-preview {
    @include row;
    justify-content: space-between;
    margin-top: 15px;
}

.document-preview-title {
    @include row-centered;
    justify-content: space-between;
    color: $primaryColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    height: 45px;
    margin-bottom: 4px;

    a {
        font-size: 14px;
        font-weight: normal;
    }
}

.document-preview-column-pdf {
    margin-right: 40px;
}

.document-preview-column-info {
    width: auto;
    flex: 1;
    position: relative;

    .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 16px;
    }
}

.document-preview-column-edit-link {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 1;
}

.document-preview-info {
    margin: 0;
    width: 100%;
    flex: 1;

    .extracted-preview-general-fields {
        width: 100%;
        tr {
            &:hover {
                @include container-shadow;
            }
        }
    }

    .extracted-preview-gf-label {
        width: 30%;
        padding: 2px 0;
    }

    .extracted-preview-gf-value {
        padding: 2px 0 2px 20px;
        color: #393968;
    }

    .extracted-preview {
        min-width: 0;
        width: 100%;
        height: $documentHeight;
        min-height: $documentHeight;
        overflow-y: auto;
    }

    .extracted-preview-edit {
        cursor: pointer;
    }
}

.document-preview-pdf {
    @include container-shadow;
    max-width: 600px;
    min-width: 300px;
}

.document-tab-bar {
    @include row;
    margin-bottom: 20px;
    height: 45px;

    .ant-select {
        @include container-shadow;
        height: 45px;
        width: 150px;

        .ant-select-selector {
            border: none;
        }
    }
}

.document-tab {
    @include row-centered;
    cursor: pointer;
    height: 44px;
    font-size: 12px;
    padding: 12px 20px;
    margin-right: 20px;
    background-color: #fff;
    color: #898998;
    font-weight: 500;
    border-radius: 8px;
    max-width: 215px;
    min-width: 215px;
    white-space: nowrap;
    overflow: hidden;

    &.active {
        background-color: $secondaryColor;
        color: #fff;

        .icon svg {
            fill: #fff;
        }
    }

    .icon {
        @include size(16);
        min-width: 16px;
        margin-right: 10px;

        svg {
            fill: #898998;
        }
    }

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.import-result-modal {
    .icon {
        padding: 37px;
    }

    .ant-modal-footer .ant-btn {
        margin: auto;
        display: block;
    }

    .import-result-details {
        background-color: #f8f8f8;
        border-radius: 8px;
        color: #2c3d53;
        font-size: 12px;
        padding: 15px;
        margin-top: 15px;
    }
}

.file-pagination.pdf-view {
    left: $sideMenuWidth + 150px;
    bottom: $pageVerticalPadding;
}

.import-options {
    margin-left: 15px;
    margin-right: -8px;
}

.view-results-error {
    color: $errorColor;
}

.document-view-container {
    position: relative;
    width: 600px;

    .zoom {
        right: auto;
        left: -55px;
        top: -25px;
        transform: scale(0.7);
    }
}

.document-view-wrapper {
    position: relative;
    height: $documentHeight;
    overflow: auto;
}

.document-view {
    position: absolute;

    .react-pdf__Page {
        background-color: transparent !important;
    }
}

.match-view {
    overflow-y: scroll;
    height: 460px;
    margin-bottom: 16px;
}

.document-output {
    .extracted-preview-table {
        td, th {
            padding: 8px;
        }
    }
}
