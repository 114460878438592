.overview-layout {
    @include row;
    height: calc(100% - 66px);
}

.overview-layout-left {
    flex: 3;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
}

.overview-layout-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 270px;
}

.overview-layout-kpi {
    margin-bottom: 25px;
}

.overview-layout-list {
    flex: 1;
    display: flex;
}
