.password-rules {
    margin-bottom: 16px;
}

.password-rule {
    @include row-centered;
    margin-bottom: 6px;
}

.password-rule-icon {
    border-radius: 20px;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 8px;
    background-color: #e3e5e6;

    svg {
        display: none;
    }

    &.valid {
        background-color: #23c17c;

        svg {
            display: block;
        }
    }
}

.password-rule-text {
    font-size: 12px;
}
