.selection-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.selection-area {
    position: absolute;
    background-color: rgba(255, 203, 92, 0.4);
    border: 2px dashed rgba(151, 151, 151, 0.4);
}

.selection-area-field {
    opacity: 0;

    &.active {
        opacity: 1;
    }
}

.step-title {
    font-size: 18px;
}

.step-title-active {
    font-weight: 700;
}

.template-menu {
    width: 50px;
    height: 25px;
    cursor: pointer;

    svg {
        color: #9998b7;
    }
}

.item-right {
    @include row-centered;

    .badge {
        margin-right: 10px;
    }
}

.item-center {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.confirm-menu {
    @include container-shadow;
    padding: 6px;
    position: absolute;
    margin-left: -25px;
    margin-top: 6px;

    &::after {
        position: absolute;
        content: '';
        top: -5px;
        left: 20px;
        border-top: 5px solid #fff;
        border-right: 5px solid #fff;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        transform: rotate(-45deg);
    }

    &.hidden {
        display: none;
    }
}

.view-field-value {
    cursor: pointer;
    @include size(16);
    margin: 0 10px;

    svg {
        color: #9998b7;
    }
}

.template-tip {
    @include row-centered;
    position: fixed;
    bottom: 0;
    left: $sideMenuWidth;
    background-color: #ffdf9c;
    height: 105px;
    width: 410px + $pageHorizontalPadding;
    z-index: 99;
}

.template-tip-icon {
    @include size(50);
    margin: 20px;
}
.template-tip-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}

.template-tip-text {
    margin: 20px 20px 20px 0;
    color: $primaryColor;
    font-size: 14px;
}

.mark-table-divider {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mark-table-content {
    margin-top: 15px;
}

.mark-table-settings {
    border: none;
    background-color: transparent;
    margin-bottom: 15px;

    .toggle-button {
        margin: 15px 0;
    }

    .ant-select {
        width: 100%;
    }

    .ant-collapse-item {
        border: none;
    }

    &.ant-collapse .ant-collapse-header {
        padding: 0;
        line-height: 1.5;
        color: $actionColor;
    }

    .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
            padding: 10px 0 0;
        }
    }
}

.ant-notification .ant-notification-notice.save-template-notification {
    width: 540px;

    .ant-notification-notice-message {
        margin-bottom: 0;
    }

    .ant-notification-notice-close {
        @include size(35);
    }
}

.save-template-complete {
    @include row-centered;
}

.save-template-title {
    font-size: 18px;
    font-weight: 600;
}

.save-template-icon {
    @include size(34);
    margin-right: 15px;

    svg {
        fill: #b74ca4;
    }
}

.save-template-icon-big {
    @include size(136);
    padding: 30px;
    margin-bottom: 15px;
    border-radius: 100px;
    background-color: #f8f8f8;

    svg {
        fill: #b74ca4;
    }
}

.save-template-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 20px 60px;
}

.save-template-modal {
    .ant-modal-footer {
        display: flex;
        justify-content: center;
    }
}

.ant-radio-group.view-toggle {
    * {
        border: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
    }

    .ant-radio-button-wrapper {
        height: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ededed;

        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: #8c4195;
        }
    }

    .ant-radio-button-wrapper-checked {
        .view-toggle-icon {
            svg {
                fill: #fff;
            }
        }
    }
}

.view-toggle-option {
    @include row-centered;
    min-width: 110px;
    white-space: nowrap;
    font-size: 14px;
    justify-content: center;
}

.view-toggle-icon {
    @include size(16);
    min-width: 16px;
    margin-right: 6px;
    margin-top: -7px;
}

.single-item-multiline {
    width: 100%;

    .divider {
        margin: 10px 20px 10px 0;
    }
}

.single-item-multiline-row {
    @include row-centered;
    justify-content: space-between;
    position: relative;

    &.first {
        .selection-button {
            margin: -15px 5px;
        }
    }

    &.second {
        justify-content: flex-end;
        margin-right: 22px;
        height: 24px;
    }

    .selection-button,
    .badge {
        &:not(:last-child) {
            margin: 0 5px;
        }
    }
}

.single-item-multiline-description {
    color: $minorTextColor;
    font-size: 11px;
    margin: auto;
    position: absolute;
    left: 0;
}

.scanned-value {
    color: $minorTextColor;
    font-size: 12px;
}

.columns-to-template-item-right {
    width: 150px;
}

.columns-to-template-select {
    width: 150px;
}

.columns-to-template-free-text {
    width: 150px;
    margin-top: 10px;
}

.split-docs-by-fields {
    width: 100%;
    margin-top: 4%;
}
