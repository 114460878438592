.page-header {
    @include row;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 50px;

    h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }
}

.page-header-controls {
    @include row-centered;

    & > * {
        margin: 0 8px;
    }
}
