* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-size: $defaultFontSize;
    -webkit-font-smoothing: antialiased;
    color: $defaultFontColor;
    background-color: $defaultBackground;
    min-height: 100vh;
    display: flex;
    max-width: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
    min-height: 100%;
    width: 100%;
    max-width: 100vw;
}

input,
select {
    outline: none;
}

h1,
h2,
h3,
h4,
h5 {
    color: $primaryColor;
    margin: 0;
}

h1 {
    font-size: 32px;
    font-weight: 600;
}

h2 {
    font-size: 20px;
    font-weight: 400;
}

a {
    color: $linkColor;
    text-decoration: none;
}

textarea {
    margin: 0;
    padding: 0;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    padding: 8px;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}
