@mixin row {
    display: flex;
    flex-direction: row;
}

@mixin row-centered {
    @include row;
    align-items: center;
}

@mixin fullsize {
    width: 100%;
    height: 100%;
}

@mixin primaryHover {
    background-color: adjust-color($color: $secondaryColor, $lightness: 15%);
}

@mixin primaryActive {
    background-color: adjust-color($color: $secondaryColor, $lightness: -10%);
}

@mixin size($size) {
    width: $size + px;
    height: $size + px;
}

@mixin container-shadow {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(45, 46, 116, 0.07);
}

@mixin prefix($height, $color) {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: $height;
    width: 6px;
    background-color: $color;
    border-radius: 8px 0 0 8px;
}
