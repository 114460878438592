$leftColumnWidth: 410px;

.template {
    @include row;
    flex: 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    position: relative;
}

.template-left {
    padding-right: 25px;
    padding-top: 20px;
    width: $leftColumnWidth;
    min-width: $leftColumnWidth;
    overflow-y: auto;
}

.template-right {
    width: calc(100% - $leftColumnWidth);
    max-width: calc(100% - $leftColumnWidth);
    max-height: 100%;
    height: 100%;
    overflow: hidden;
}
