.template-types-list {
    margin: 12px 0 20px;
    max-height: calc(100% - 120px);
    overflow-y: auto;
}

.template-types-list-item {
    @include row-centered;
    margin: 8px 0;
    padding: 10px 10px 10px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(45, 46, 116, 0.05);
    border: none;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.template-type {
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
        color: #9998b7;
    }
}

.template-types-form {
    position: relative;
}

.template-types-form-controls {
    position: sticky;
    background-color: #fff;
    bottom: -25px;
    left: 0;
    padding: 25px;
    margin: 0 -25px 0;
}

.template-type-selected {
    &::before {
        content: '';
        display: block;
        border-radius: 8px 0px 0px 8px;
        background: $secondaryColor;
        box-shadow: 0px 0px 20px 0px rgba(45, 46, 116, 0.05);
        height: 100%;
        width: 8px;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.template-field {
    @include container-shadow;
    @include row-centered;
    padding: 0 18px;
    background-color: rgba(45, 46, 116, 0.05);
    justify-content: space-between;
    border-radius: 8px;
    background: #fff;
    height: 44px;
    margin: 7px 0;

    &.selected {
        box-shadow: 0px 0px 10px 0px rgba(45, 46, 116, 0.07);
    }

    &.dragging {
        box-shadow: 0px 0px 10px 0px rgba(45, 46, 116, 0.07);
        transform: rotate(2deg);
    }

    &.drag-placeholder {
        border: 1px dashed #cfcfcf;
        background: #f6faff;

        * {
            display: none;
        }
    }
}

.template-field-left {
    @include row-centered;
    cursor: pointer;

    span {
        overflow: hidden;
        white-space: nowrap;
        max-width: 160px;
        text-overflow: ellipsis;
    }
}

.template-field-drag-handle {
    @include row-centered;
    width: 24px;
    height: 44px;
    margin-right: 5px;

    &::before {
        content: '';
        display: block;
        height: 3px;
        width: 12px;
        border-top: 1px solid $linkColor;
        border-bottom: 1px solid $linkColor;
        margin: auto;
    }
}

.template-field-required {
    font-size: 12px;
    color: $minorTextColor;
}

.template-field-edit-button {
    font-size: 12px;
    padding: 0 0 0 25px;
}

.template-fields-form {
    @include container-shadow;
    padding: 18px;
    margin-bottom: 20px;

    .ant-form-item-label {
        width: 40%;
        text-align: left;

        label::after {
            display: none;
        }
    }
}

.template-fields-form-checkboxes {
    @include row-centered;
    justify-content: flex-end;
    margin-left: 130px;

    label {
        font-size: 13px;
    }
}

.template-fields-form-checkboxes-required label {
    color: $actionColor;
    font-weight: 600;
}

.template-fields-buttons {
    @include row-centered;
    justify-content: space-between;
}

.template-field-add {
    position: absolute;
    top: 17px;
    right: 20px;
}

.template-fields-container {
    position: relative;
}

.template-fields-empty {
    margin: 0;
}

.template-type-profile-row {
    @include row;
    position: relative;

    .ant-form-item {
        flex: 3;
    }

    .ant-form-item.template-type-profile-id {
        flex: 1;
        margin-left: 17px;
    }

    .tooltip-container {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.template-fields-error {
    margin-bottom: 20px;
}
