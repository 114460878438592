.app-notification {
    &.ant-notification-notice {
        padding: 15px 15px 24px 40px!important;
        width: 460px!important;

        .ant-notification-notice-close {
            top: 15px;
            right: 15px;
        }

        .ant-notification-notice-with-icon {
            min-height: 50px;

            .ant-notification-notice-message {
                font-size: 18px;
                margin-bottom: 0;
            }

            .ant-notification-notice-description {
                color: #898998;
                font-size: 12px;
            }
        }
    }

    .ant-notification-notice-icon {
        left: 15px;
        top: 15px;
    }
}

.notification-icon {
    border-radius: 8px;
    width: 50px;
    height: 50px;
    padding: 10px;

    svg {
        fill: #fff;
    }

    &.error {
        background-color: #ff7474;
    }

    &.new-invoice {
        background-color: #bc4ca6;
    }
}

.link-to-import {
    position: absolute;
    right: 45px;
    top: 37px;
    font-size: 15px;
    text-align: right;
}
